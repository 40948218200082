import RealTimeMap from './RealTimeMap';
import NewDashboard from './NewDashboard/NewDashboard';
import VideoManagement from './VideoManagement';
import Loading from './Loading';
import Reports from './Reports';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useEffect, useState } from 'react';
import { AlertsProvider } from '../contexts/AlertsContext';
import { DeviceFilterProvider } from '../contexts/DeviceFilterContext';
import { FeatureFlagProvider } from '../contexts/FeatureFlagContext';
import DeviceManagement from './DeviceManagement';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from './ErrorBoundary';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchUserRoles } from '../api/beaverApi';
import { fetchUserMapping, getUserRoleFromToken, fetchConfig, fetchRetentionPeriod } from '../api/beaverApi';
import Announcements from './AnnouncementPopup';
import mixpanel from 'mixpanel-browser';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import { trackPageView } from '../utils/analytics';
import Settings from './Settings/Settings';
import PlatformHelp from './PlatformHelp';
const REQUIRED_AUTH_VERSION = '1.0';

function App({ isAuthenticated, user, logout }) {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const [config, setConfig] = useState(null);
  const [userSub, setUserSub] = useState(user.sub);
  const [featureMode, setFeatureMode] = useState('prod');
  const [error, setError] = useState(null);
  const [retentionPeriodDays, setRetentionPeriodDays] = useState(30);
  const [showOutageAlert, setShowOutageAlert] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [realTimeMapRefresh, setRealTimeMapRefresh] = useState(0);
  const [parentOrgUserId, setParentOrgUserId] = useState(null);

  const refreshRealTimeMap = () => {
    setRealTimeMapRefresh((prev) => prev + 1);
  };

  useEffect(() => {
    if (window.location.pathname === '/') {
      // refreshRealTimeMap();
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const getAndStoreAccessToken = async () => {
      if (!isAuthenticated) return;

      try {
        localStorage.setItem('token_fetch_in_progress', Date.now().toString());

        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: 'openid profile email',
          },
        });

        if (accessToken) {
          localStorage.setItem('access_token', accessToken);
          localStorage.setItem('auth_version', REQUIRED_AUTH_VERSION);
        }
        localStorage.removeItem('token_fetch_in_progress');
      } catch (error) {
        console.error('Token fetch failed:', error);
        localStorage.removeItem('token_fetch_in_progress');

        if (error.error === 'invalid_grant' || error.response?.status === 400) {
          console.error('Invalid grant or 400 error - session may be expired');
        }
        localStorage.clear();
        logout({ returnTo: window.location.origin });
      }
    };

    if (!isLoading && isAuthenticated) {
      getAndStoreAccessToken();
    }
  }, [isAuthenticated, getAccessTokenSilently, isLoading, logout]);

  useEffect(() => {
    const getUserRole = () => {
      if (isAuthenticated && user) {
        try {
          const roleName = getUserRoleFromToken(user);
          setUserRole(roleName);
        } catch (error) {
          console.error('Error getting user role:', error);
        }
      }
    };

    getUserRole();
  }, [isAuthenticated, user]);

  useEffect(() => {
    let isMounted = true;

    async function fetchUserMappingAndConfig() {
      try {
        if (!isAuthenticated || isLoading) return;

        const mapping = await fetchUserMapping(getAccessTokenSilently);
        if (!isMounted) return;

        const { mapped } = mapping;
        setUserSub(mapped.userId);
        setFeatureMode(mapped.mode);
        if (mapped.parentOrgUserId) setParentOrgUserId(mapped.parentOrgUserId);
        const [configFromDB, retentionPeriodDays] = await Promise.all([
          fetchConfig(mapped.userId),
          fetchRetentionPeriod(mapped.userId),
        ]);

        setConfig(configFromDB);
        setRetentionPeriodDays(retentionPeriodDays);
      } catch (error) {
        if (!isMounted) return;
        console.error('Failed to fetch user mapping or config:', error);
        setError(error.message || 'Failed to load user configuration. Please try again later.');
      }
    }

    if (user?.sub && isAuthenticated && !isLoading) {
      fetchUserMappingAndConfig();
    }

    return () => {
      isMounted = false;
    };
  }, [user?.sub, isAuthenticated, isLoading, getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && user) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
        debug: true,
        track_pageview: false,
        persistence: 'localStorage',
        ignore_dnt: true,
        identify_prefix: '',
        disable_persistence: false,
        disable_cookie: true,
        secure_cookie: true,
      });

      mixpanel.reset();
      mixpanel.identify(user.sub);
      mixpanel.people.set({
        $name: user.name,
        $email: user.email,
        feature_mode: featureMode,
        role: userRole,
      });

      trackPageView(true);
    }
  }, [isAuthenticated, user]);

  if (error) {
    return (
      <div className="error-container">
        <p className="error-message">{error}</p>
      </div>
    );
  }

  if (!config) {
    return <Loading />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <div>Please log in</div>;
  }

  return (
    <ErrorBoundary>
      <AlertsProvider user={user}>
        <FeatureFlagProvider>
          <Router>
            <div style={{ display: 'flex', overflow: 'clip' }}>
              <DeviceFilterProvider userId={userSub} userRole={userRole}>
                <Sidebar userName={user.name} userEmail={user.email} featureMode={featureMode} userRole={userRole} />
                <div className="main-content-area">
                  <Announcements
                    userId={userSub}
                    isOverwatch={featureMode === 'dev' && user.name.startsWith('overwatch')}
                  />
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <RealTimeMap
                          refreshTrigger={realTimeMapRefresh}
                          isAuthenticated={isAuthenticated}
                          logout={logout}
                          userId={userSub}
                          config={config}
                          setConfig={setConfig}
                          userRole={userRole}
                          parentOrgUserId={parentOrgUserId}
                          retentionPeriodDays={retentionPeriodDays}
                        />
                      }
                    />
                    <Route
                      path="videos"
                      element={
                        <VideoManagement
                          isAuthenticated={isAuthenticated}
                          logout={logout}
                          userId={userSub}
                          parentOrgUserId={parentOrgUserId}
                          retentionPeriodDays={retentionPeriodDays}
                          userName={user.name}
                          userRole={userRole}
                        />
                      }
                    />
                    <Route
                      path="dashboard"
                      element={
                        <NewDashboard userId={userSub} userRole={userRole} retentionPeriodDays={retentionPeriodDays} />
                      }
                    />
                    <Route
                      path="reports"
                      element={
                        <Reports
                          userId={userSub}
                          userRole={userRole}
                          userEmail={user.email}
                          parentOrgUserId={parentOrgUserId}
                        />
                      }
                    />
                    <Route path="help" element={<PlatformHelp />} />
                    <Route
                      path="settings"
                      element={
                        userRole === 'guard' ? (
                          <Navigate to="/" replace />
                        ) : (
                          <Settings
                            userId={userSub}
                            featureMode={featureMode}
                            userRole={userRole}
                            parentOrgUserId={parentOrgUserId}
                          />
                        )
                      }
                    />
                    {featureMode === 'dev' && (
                      <Route path="device-management" element={<DeviceManagement userId={userSub} />} />
                    )}
                  </Routes>
                </div>
              </DeviceFilterProvider>
            </div>
            <ToastContainer />
          </Router>
        </FeatureFlagProvider>
      </AlertsProvider>
    </ErrorBoundary>
  );
}

export default App;
