import React, { useState, useEffect } from 'react';
import '../styles/Sidebar.css';
import {
  RiCompass3Line,
  RiFlashlightLine,
  RiVideoLine,
  RiSettings4Line,
  RiLogoutBoxRLine,
  RiBuildingLine,
  RiLoader4Line,
  RiPencilLine,
  RiMoonLine,
  RiSunLine,
  RiComputerLine,
  RiQuestionLine,
} from 'react-icons/ri';
import { useAuth0 } from '@auth0/auth0-react';
import Tooltip from '@mui/material/Tooltip';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { fetchOrganizationsFromCosmos } from '../api/beaverApi';
import { Menu, MenuItem } from '@mui/material';
import { useDeviceFilter } from '../contexts/DeviceFilterContext';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';

function Sidebar({ userName, userEmail, featureMode, userRole }) {
  const { isAuthenticated, user, logout } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [hoverState, setHoverState] = useState({
    plix: false,
    search: false,
    video: false,
    settings: false,
  });
  const { selectedOrg, setSelectedOrg, setDeviceFilter } = useDeviceFilter();
  const [organizations, setOrganizations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [themeAnchorEl, setThemeAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sidebarReady, setSidebarReady] = useState(false);
  const [themePreference, setThemePreference] = useState(() => {
    const storedPreference = localStorage.getItem('themePreference');
    return storedPreference || 'system';
  });

  useEffect(() => {
    if (userName.startsWith('overwatch')) {
      setIsLoading(true);
      fetchOrganizationsFromCosmos()
        .then((orgs) => {
          setOrganizations(orgs);
          setSidebarReady(true);
        })
        .finally(() => setIsLoading(false));
    } else {
      setSidebarReady(true);
      setIsLoading(false);
    }
  }, [userName]);

  useEffect(() => {
    if (selectedOrg) {
      localStorage.setItem('selectedOrg', selectedOrg);
    }
  }, [selectedOrg]);

  useEffect(() => {
    const updateTheme = () => {
      if (themePreference === 'system') {
        const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
      } else {
        document.documentElement.setAttribute('data-theme', themePreference);
      }
    };

    updateTheme();

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => {
      if (themePreference === 'system') {
        updateTheme();
      }
    };

    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, [themePreference]);

  const handleMouseEnter = (logo) => {
    setHoverState((prevState) => ({ ...prevState, [logo]: true }));
  };

  const handleMouseLeave = (logo) => {
    setHoverState((prevState) => ({ ...prevState, [logo]: false }));
  };

  const handleOrgClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOrgClose = () => {
    setAnchorEl(null);
  };

  const handleThemeClick = (event) => {
    setThemeAnchorEl(event.currentTarget);
  };

  const handleThemeClose = () => {
    setThemeAnchorEl(null);
  };

  const handleThemeSelect = (theme) => {
    setThemePreference(theme);
    localStorage.setItem('themePreference', theme);
    handleThemeClose();
  };

  const getThemeIcon = () => {
    if (themePreference === 'system') {
      return <RiComputerLine className="icon-black" />;
    }
    return themePreference === 'dark' ? <RiMoonLine className="icon-black" /> : <RiSunLine className="icon-black" />;
  };

  const handleOrgSelect = (orgUserId) => {
    setSelectedOrg(orgUserId);
    // Reset device filter when org changes
    setDeviceFilter(null);
    localStorage.removeItem('deviceFilter');
    handleOrgClose();
  };

  const getSelectedOrgName = () => {
    if (!organizations) return 'Loading...';
    if (isLoading) return 'Loading...';
    const org = organizations.find((org) => org.orgUserId === selectedOrg);
    return org ? org.orgName : 'Select Organization';
  };

  // Prevent interaction until ready
  const handleNavigation = (path) => {
    if (!sidebarReady) return;
    navigate(path);
  };

  // Helper function to check if route is active
  const isActive = (path) => location.pathname === path;

  const loadingIcon = isLoading ? RiLoader4Line : RiBuildingLine;

  return (
    <div className="sidebar">
      <Tooltip title={<h2>Live View</h2>} placement="right" arrow>
        <div
          className={`logo-item ${!sidebarReady ? 'disabled' : ''} ${isActive('/') ? 'active' : ''}`}
          onMouseEnter={() => handleMouseEnter('plix')}
          onMouseLeave={() => handleMouseLeave('plix')}
          onClick={() => handleNavigation('/')}
        >
          <Link to="/" className={!sidebarReady ? 'disabled' : ''}>
            <RiCompass3Line className="icon-black" />
          </Link>
        </div>
      </Tooltip>

      <Tooltip title={<h2>Dashboard</h2>} placement="right" arrow>
        <div
          className={`logo-item ${!sidebarReady ? 'disabled' : ''} ${isActive('/dashboard') ? 'active' : ''}`}
          onMouseEnter={() => handleMouseEnter('dashboard')}
          onMouseLeave={() => handleMouseLeave('dashboard')}
          onClick={() => handleNavigation('/dashboard')}
        >
          <Link to="/dashboard" className={!sidebarReady ? 'disabled' : ''}>
            <RiFlashlightLine className="icon-black" />
          </Link>
        </div>
      </Tooltip>

      <Tooltip title={<h2>Reports</h2>} placement="right" arrow>
        <div
          className={`logo-item ${!sidebarReady ? 'disabled' : ''} ${isActive('/reports') ? 'active' : ''}`}
          onClick={() => handleNavigation('/reports')}
        >
          <Link to="/reports" className={!sidebarReady ? 'disabled' : ''}>
            <RiPencilLine className="icon-black icon-pen" />
          </Link>
        </div>
      </Tooltip>

      <Tooltip title={<h2>Asset Library</h2>} placement="right" arrow>
        <div
          className={`logo-item ${!sidebarReady ? 'disabled' : ''} ${isActive('/videos') ? 'active' : ''}`}
          onMouseEnter={() => handleMouseEnter('video')}
          onMouseLeave={() => handleMouseLeave('video')}
          onClick={() => handleNavigation('/videos')}
        >
          <Link to="/videos" className={!sidebarReady ? 'disabled' : ''}>
            <RiVideoLine className="icon-black" />
          </Link>
        </div>
      </Tooltip>

      <div className="logo-item-bottom">
        {isAuthenticated && userName.startsWith('overwatch') && (
          <Tooltip title={<h2>{getSelectedOrgName()}</h2>} placement="right" arrow>
            <div className="logo-item" onClick={handleOrgClick}>
              {isLoading ? <RiLoader4Line className="icon-black spin" /> : <RiBuildingLine className="icon-black" />}
            </div>
          </Tooltip>
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleOrgClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {isLoading || !organizations ? (
            <MenuItem disabled>Loading organizations...</MenuItem>
          ) : (
            organizations
              .sort((a, b) => a.orgName.localeCompare(b.orgName))
              .map((org) => (
                <MenuItem
                  key={org.orgUserId}
                  onClick={() => handleOrgSelect(org.orgUserId)}
                  selected={org.orgUserId === selectedOrg}
                >
                  {org.orgName}
                </MenuItem>
              ))
          )}
        </Menu>

        <Tooltip title={<h2>Help</h2>} placement="right" arrow>
          <div
            className={`logo-item ${!sidebarReady ? 'disabled' : ''} ${isActive('/help') ? 'active' : ''}`}
            onMouseEnter={() => handleMouseEnter('help')}
            onMouseLeave={() => handleMouseLeave('help')}
            onClick={() => handleNavigation('/help')}
          >
            <Link to="/help" className={!sidebarReady ? 'disabled' : ''}>
              <RiQuestionLine className="icon-black" />
            </Link>
          </div>
        </Tooltip>
        {isAuthenticated &&
          (!userName.startsWith('overwatch') || selectedOrg !== PLIX_OVERWATCH_ACCOUNT) &&
          userRole !== 'guard' && (
            <Tooltip title={<h2>Settings</h2>} placement="right" arrow>
              <div
                className={`logo-item ${!sidebarReady ? 'disabled' : ''} ${isActive('/settings') ? 'active' : ''}`}
                onMouseEnter={() => handleMouseEnter('settings')}
                onMouseLeave={() => handleMouseLeave('settings')}
                onClick={() => handleNavigation('/settings')}
              >
                <Link to="/settings" className={!sidebarReady ? 'disabled' : ''}>
                  <RiSettings4Line className="icon-black" />
                </Link>
              </div>
            </Tooltip>
          )}

        <Tooltip title={<h2>Theme</h2>} placement="right" arrow>
          <div className="logo-item theme-toggle" onClick={handleThemeClick}>
            {getThemeIcon()}
          </div>
        </Tooltip>
        <Menu
          anchorEl={themeAnchorEl}
          open={Boolean(themeAnchorEl)}
          onClose={handleThemeClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={() => handleThemeSelect('light')} selected={themePreference === 'light'}>
            <RiSunLine style={{ marginRight: '8px' }} /> Light
          </MenuItem>
          <MenuItem onClick={() => handleThemeSelect('dark')} selected={themePreference === 'dark'}>
            <RiMoonLine style={{ marginRight: '8px' }} /> Dark
          </MenuItem>
          <MenuItem onClick={() => handleThemeSelect('system')} selected={themePreference === 'system'}>
            <RiComputerLine style={{ marginRight: '8px' }} /> System
          </MenuItem>
        </Menu>

        {isAuthenticated && (
          <Tooltip title={<h2>Log out</h2>} placement="right" arrow>
            <div className="logo-item" onClick={() => logout({ returnTo: window.location.origin })}>
              <RiLogoutBoxRLine className="icon-black" />
            </div>
          </Tooltip>
        )}

        {isAuthenticated && (
          <Tooltip title={<h2>{userName}</h2>} placement="right" arrow>
            <div
              className="user-info"
              onMouseEnter={() => handleMouseEnter('user')}
              onMouseLeave={() => handleMouseLeave('user')}
            >
              {userEmail.endsWith('cpgsecure.com') ? (
                <img className="user-avatar-logo" src="/logos/cpg_logo.webp"></img>
              ) : (
                <img className="user-avatar" src={user.picture} alt="User Avatar" />
              )}
            </div>
          </Tooltip>
        )}
      </div>

      <style>
        {`
          .disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        `}
      </style>
    </div>
  );
}

export default Sidebar;
