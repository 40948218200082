import React, { useState, useEffect, useRef } from 'react';
import '../styles/MobilePanel.css';
import DeviceAssignment from './DeviceAssignment';
import SiteAssignment from './SiteAssignment'; // Add this import
import VideosList from './VideosList';
import Leaderboard from './Leaderboard';
import Card from './Card';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faCompass, faMapMarkerAlt, faBell, faVideo, faTrophy } from '@fortawesome/free-solid-svg-icons';

const MobilePanel = ({
  userId,
  userRole,
  devices,
  alerts,
  handleDeviceClick,
  handleCardClick,
  config,
  activeDevice,
  NoAlertsMessage,
  isExpanded,
  onToggle,
  handleDeviceSelect,
  deviceShifts,
  updateDeviceAssignment,
  geofences,
  setSelectedGeofence,
  onSiteClick,
  organizationId,
  onGeofenceUpdate,
  previewGeofence,
  onPreviewGeofence,
  onPreviewGeofenceRadiusChange,
  videos,
  onVideoSelect,
  leaderboardData,
  onWeekChange,
  fetchLeaderboardData,
  polygonGeofences,
  setPolygonGeofences,
  editMode,
  setEditMode,
  activeGeofence,
  setActiveGeofence,
  originalGeofenceRef,
  storeOriginalGeofenceState,
  clearOriginalGeofenceState,
  getOriginalGeofenceState,
  parentOrgUserId,
  selectedGeofence,
}) => {
  const [activeTab, setActiveTab] = useState('devices');
  const panelRef = useRef(null);
  const [isWarningFading, setIsWarningFading] = useState(false);

  const singleDeviceId = devices.length === 1 ? devices[0].deviceId : null;

  const togglePanel = (tab) => {
    if (tab === activeTab && isExpanded) {
      onToggle(false);
    } else {
      onToggle(true);
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target) && isExpanded) {
        onToggle(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isExpanded, onToggle]);

  const getDaysLeft = () => {
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
    const daysUntilSunday = dayOfWeek === 0 ? 7 : 7 - dayOfWeek;
    return daysUntilSunday + 1;
  };

  return (
    <>
      <div ref={panelRef} className={`mobile-panel ${isExpanded ? 'expanded' : ''}`}>
        <div className="mobile-panel-tabs">
          <button className={`tab ${activeTab === 'devices' ? 'active' : ''}`} onClick={() => togglePanel('devices')}>
            <FontAwesomeIcon icon={faCompass} />
            <span>Devices</span>
          </button>
          <button className={`tab ${activeTab === 'sites' ? 'active' : ''}`} onClick={() => togglePanel('sites')}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <span>Sites</span>
          </button>
          <button
            className={`tab central ${activeTab === 'leaderboard' ? 'active' : ''}`}
            onClick={() => togglePanel('leaderboard')}
          >
            <div className="central-content">
              <FontAwesomeIcon icon={faTrophy} />
              {activeTab !== 'leaderboard' && (
                <span className="days-left">
                  {getDaysLeft()} {getDaysLeft() === 1 ? 'day' : 'days'} left
                </span>
              )}
            </div>
          </button>
          <button className={`tab ${activeTab === 'alerts' ? 'active' : ''}`} onClick={() => togglePanel('alerts')}>
            <FontAwesomeIcon icon={faBell} />
            <span>Alerts</span>
          </button>
          <button className={`tab ${activeTab === 'videos' ? 'active' : ''}`} onClick={() => togglePanel('videos')}>
            <FontAwesomeIcon icon={faVideo} />
            <span>Videos</span>
          </button>
        </div>
        {isExpanded && (
          <div className="mobile-panel-content">
            {activeTab === 'devices' && (
              <DeviceAssignment
                userId={userId}
                handleDeviceClick={handleDeviceClick}
                devices={devices}
                deviceShifts={deviceShifts}
                updateDeviceAssignment={updateDeviceAssignment}
                userRole={userRole}
                isMobile={true}
              />
            )}
            {activeTab === 'sites' && (
              <SiteAssignment
                geofences={geofences}
                devices={devices}
                deviceShifts={deviceShifts}
                onSiteClick={onSiteClick}
                organizationId={organizationId}
                onGeofenceUpdate={onGeofenceUpdate}
                handleDeviceSelect={handleDeviceSelect}
                onPreviewGeofence={onPreviewGeofence}
                selectedGeofence={selectedGeofence}
                setSelectedGeofence={setSelectedGeofence}
                userRole={userRole}
                parentOrgUserId={parentOrgUserId}
                polygonGeofences={polygonGeofences}
                setPolygonGeofences={setPolygonGeofences}
                editMode={editMode}
                setEditMode={setEditMode}
                activeGeofence={activeGeofence}
                setActiveGeofence={setActiveGeofence}
                originalGeofenceRef={originalGeofenceRef}
                storeOriginalGeofenceState={storeOriginalGeofenceState}
                clearOriginalGeofenceState={clearOriginalGeofenceState}
                getOriginalGeofenceState={getOriginalGeofenceState}
                isMobile={true}
              />
            )}
            {activeTab === 'alerts' && (
              <div className="alerts-list">
                {alerts.length > 0 ? (
                  alerts.map((alert) => (
                    <Card
                      userId={userId}
                      key={alert.id}
                      initialCard={alert}
                      icon={config.transitionsConfig.find((t) => t.title === alert.Label)?.icon}
                      onDeviceSelect={handleDeviceSelect}
                      onCardClick={handleCardClick}
                    />
                  ))
                ) : (
                  <NoAlertsMessage />
                )}
              </div>
            )}
            {activeTab === 'videos' && <VideosList videos={videos} onVideoSelect={onVideoSelect} />}
            {activeTab === 'leaderboard' && (
              <Leaderboard
                leaderboardData={leaderboardData}
                onWeekChange={onWeekChange}
                fetchLeaderboardData={fetchLeaderboardData}
                singleDeviceId={singleDeviceId}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MobilePanel;
