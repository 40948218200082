import React from 'react';
import '../../../styles/Settings/SettingsControls/SectionsTab.css';

const SectionsTab = ({ selectedSection, setSelectedSection, featureMode }) => {
  return (
    <div className="sections-tab-container">
      <button
        className={`section-tab ${selectedSection === 'devices' ? 'active' : ''}`}
        onClick={() => setSelectedSection('devices')}
      >
        <i className="icon-device"></i>
        Device Management
      </button>
      <button
        className={`section-tab ${selectedSection === 'personnel' ? 'active' : ''}`}
        onClick={() => setSelectedSection('personnel')}
      >
        <i className="icon-users"></i>
        Personnel Accounts
      </button>
      <button
        className={`section-tab ${selectedSection === 'triggers' ? 'active' : ''}`}
        onClick={() => setSelectedSection('triggers')}
      >
        <i className="icon-bell"></i>
        Trigger Phrases
      </button>
      <button
        className={`section-tab ${selectedSection === 'reports' ? 'active' : ''}`}
        onClick={() => setSelectedSection('reports')}
      >
        <i className="icon-mail"></i>
        Activity Reports
      </button>
      {featureMode === 'dev' && (
        <button
          className={`section-tab ${selectedSection === 'advanced' ? 'active' : ''}`}
          onClick={() => setSelectedSection('advanced')}
        >
          <i className="icon-settings"></i>
          Advanced Settings
        </button>
      )}
    </div>
  );
};

export default SectionsTab;
